[data-key='num-sp'] {
  display: none;
}

.sd-container-modern .sv-dropdown_select-wrapper input[type=text] {
  outline: none !important;
  border: none;
  box-shadow: none;
}

.border-kill {
  border: none !important;
}

.spg-search-editor_input {
  box-sizing: initial !important;
}

.spg-search-editor_container {
  height: auto;
  @apply my-auto w-full h-14;
}

.spg-search-editor_container input {
  @apply w-full;
}

.sd-table__cell--actions:not(.sd-table__cell--vertical):last-child .sd-action-bar {
  background: transparent !important;
}

.markdown-text a {
  @apply text-ev-blue;
  @apply underline
}
