@tailwind base;
@tailwind components;
@tailwind utilities;

.swing {
  animation: swing ease-in-out 1s infinite alternate;
  transform-origin: center -20px;
  float:left;
  rotate: 347deg;
}

.swing img {
  display: block;
}

@keyframes swing {
  0% { transform: rotate(3deg); }
  100% { transform: rotate(-3deg); }
}

img.shake {
  animation: shake 0.5s;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.animate-heartbeat {
  animation: heartbeat 2s infinite;
}

@keyframes heartbeat {
  0% { transform: scale(0.8); }
  50% { transform: scale(1); }
  100% { transform: scale(0.8); }
}
