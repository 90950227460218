.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.animate-heartbeat {
  animation: heartbeat 2s infinite;
}

@keyframes heartbeat {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.8);
  }
}

.ag-theme-balham {
  --ag-value-change-value-highlight-background-color: #0F4FA0;
}

.ag-theme-balham .ag-row-hover {
  color: black !important;
}
